import React, { useLayoutEffect } from "react"
import "./hero.scss"
import Container from "../container/container"
import MainHeading from "../mainheading/mainheading"
import Button from "../button/button"
import { ArrowIcon, BreadArrow } from "../icons"
import { navigate } from "gatsby"
import ReactHtmlParser from "react-html-parser"

import Image from "../../components/image-component/image-2023"
import NeilSocialProof from "../../componentsv2/neil-social-proof/neil-social-proof"

export default function Hero({
  version = "v1",
  breadcrumb = [],
  headingTag = "h2",
  className = "",
  tags = [],
  landingPageNeilPatel = false,
  heading = "Convert 30%<br class='hideipad'/> more in<br class='hidedesktop hidetablet hidemobile'/> 90 days. <span> Guaranteed.</span>",
  subHeading = "PREDICTIVE CRO AGENCY FOR B2B SAAS",
  description = "<p>A <b>prediction engine</b> that captures winning A/B tests from 34,000<br /> websites. Plus a <b>full-service team</b> that handles design and dev. <br />More wins. Faster results.<br /></p><p><b>Performance pricing.</b> No fee until we deliver.</p>",
  heroImage = "heroanimationversion2.png",
  video = null,
  customImageComponent = null,
  customLogoComponent = null,
  heroAltText = "hero animation",
  ctaText = "Get a Demo",
  ctaAction = null,
  sourceFolder = "homepage",
  cloudinaryPath = null,
  heroCtaIcon = <ArrowIcon />,
}) {
  useLayoutEffect(() => {
    const hashme = document.querySelectorAll(".hashme")
    hashme.forEach(item => {
      item.addEventListener("click", e => {
        e.preventDefault()
        const hash = item.getAttribute("data-hash")
        if (hash) {
          // window.location.hash = item.getAttribute("data-hash")
          const element = document.getElementById(hash)
          if (element) {
            // element.scrollIntoView(
            //   // true
            //   { behavior: "smooth", block: "start" }
            // )
            var vw = Math.max(
              document.documentElement.clientWidth || 0,
              window.innerWidth || 0
            )

            var headerOffset = vw < 1361 ? 70 / 2 : 82 / 2
            var elementPosition = element.getBoundingClientRect().top
            var offsetPosition = elementPosition + window.scrollY - headerOffset

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            })
          }
        }
      })
    })
  }, [])

  return (
    <Container className={`heropattern ${version} ${className}`}>
      <div className={`hero-section-outer ${!video ? `pd-160-top` : ``}`}>
        <div className={`hero-wrapper ${!video ? `relative` : ``}`}>
          <div
            className={`hero-left ${
              video
                ? `${!landingPageNeilPatel ? `pd-160-top` : `pd-148-top`}`
                : ``
            }`}
          >
            {landingPageNeilPatel && (
              <NeilSocialProof
                imageUrl={`//res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2021/lp/neilpatel.png`}
                lpText={`Spiralyze doubled my revenue!`}
                peopleDesig={`Neil Patel, Owner, Ubersuggest`}
              />
            )}
            <div className="breadcrumbs">
              {breadcrumb.map((item, index) => {
                return (
                  <span key={index}>
                    <a href={item.url}>{ReactHtmlParser(item.name)}</a>

                    <BreadArrow />
                  </span>
                )
              })}
            </div>
            <MainHeading
              heroSection={true}
              headingTag={headingTag}
              className="main-heading"
              subHeading={subHeading}
            >
              {heading}
            </MainHeading>
            {
              <div
                className="tags"
                style={
                  tags.length > 0 ? { display: "flex" } : { display: "none" }
                }
              >
                {tags.map((tag, index) => {
                  return (
                    <span
                      data-hash={tag?.toLowerCase().replace(/\s/g, "")}
                      className="hashme"
                      key={index}
                    >
                      {tag}
                    </span>
                  )
                })}
              </div>
            }
            {ReactHtmlParser(description)}
            <Button
              version={version}
              onClick={e => {
                if (!ctaAction) {
                  const params = new URLSearchParams(window.location.search)
                  let url = `/get-demo/`
                  if (params.size > 0) {
                    url = `${url}?${params.toString()}`
                  }
                  navigate(url)
                } else {
                  ctaAction()
                }
              }}
              icon={heroCtaIcon}
            >
              {ctaText}
            </Button>
          </div>
          <div className={`hero-right ${video ? `relative` : ``}`}>
            {customImageComponent ? (
              customImageComponent
            ) : (
              <>
                {video ? (
                  <video autoPlay muted playsInline>
                    {/* <source
                src="//res.cloudinary.com/spiralyze/video/upload/spiralyze2022/VIDEOS/SiteV3.webm"
                type="video/webm"
              /> */}
                    <source src={video} type="video/mp4" />
                  </video>
                ) : (
                  <Image
                    sourceFolder={sourceFolder}
                    lazyLoad={"true"}
                    alt={heroAltText}
                    fallBackImage={heroImage}
                    imgName={heroImage}
                    cloudinaryPath={
                      cloudinaryPath ??
                      "https://res.cloudinary.com/spiralyze/image/upload/f_auto/spiralyze2022"
                    }
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {customLogoComponent}
    </Container>
  )
}
